


































import { defineComponent, reactive, toRefs } from "@vue/composition-api";

export default defineComponent({
  name: "Logs",
  components: {
    UserActivity: () => import("@/components/logs/UserActivity.vue"),
  },
  setup() {
    const state = reactive({
      activeTab: "userActivity",
      renderKey: 0,
    });

    return {
      ...toRefs(state),
    };
  },
});
